<template>
  <div>
    <c-tab
      :dense="true"
      :inlineLabel="true"
      :tabItems="tabItems"
      :height.sync="height"
      v-model="tab"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :isNear.sync="isNear"
        />
      </template>
    </c-tab>
  </div>
</template>

<script>
export default {
  name: 'vendor-assess-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({

      }),
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      editable: true,
      isNear: false,
      splitter: 5,
      tab: 'vendorAssessInfo',
      tabItems: [
        { name: 'vendorAssessInfo', icon: 'edit', label: '기본정보', component: () => import(`${'./vendorAssessInfo.vue'}`) },
        { name: 'vendorAssessHistory', icon: 'history', label: '평가이력', component: () => import(`${'./vendorAssessHistory.vue'}`) },
      ],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      // code setting
      // list setting
    },
  }
};
</script>
