var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("c-tab", {
        attrs: {
          dense: true,
          inlineLabel: true,
          tabItems: _vm.tabItems,
          height: _vm.height,
        },
        on: {
          "update:height": function ($event) {
            _vm.height = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (tab) {
              return [
                _c(tab.component, {
                  tag: "component",
                  attrs: { isNear: _vm.isNear },
                  on: {
                    "update:isNear": function ($event) {
                      _vm.isNear = $event
                    },
                    "update:is-near": function ($event) {
                      _vm.isNear = $event
                    },
                  },
                }),
              ]
            },
          },
        ]),
        model: {
          value: _vm.tab,
          callback: function ($$v) {
            _vm.tab = $$v
          },
          expression: "tab",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }